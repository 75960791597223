import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";

import { appURLs } from "@/app/app-urls.mjs";
import {
  AlphaPals,
  ChestMarkers,
  DungeonMarkers,
  EggMarkers,
  EnemyCampMarkers,
  FastTravelMarkers,
  LifmunkMarkers,
  MerchantMarkers,
  NoteMarkers,
  NpcMarkers,
  PalHeatmapMarkers,
  PalMerchantMarkers,
  RespawnMarkers,
  SkillFruitMarkers,
  TowerMarkers,
} from "@/game-palworld/components/InteractiveMapIcons";
import { settingsKeys } from "@/game-palworld/InteractiveMap";
import { useQueryValues } from "@/util/router-hooks.mjs";

export default function Map() {
  const toggles = useQueryValues(settingsKeys);

  // There is 1 more level of zoom tiles available if we need.
  return (
    <MapContainer
      style={{
        width: "100%",
        position: "absolute",
        inset: 0,
        zIndex: 0,
        margin: "auto",
        background: "#152532",
      }}
      center={L.latLng(-128, 128)}
      zoom={2}
      minZoom={1}
      maxZoom={5}
      scrollWheelZoom={true}
      zoomControl={false}
      attributionControl={false}
      crs={L.CRS.Simple}
    >
      <TileLayer
        url={`${appURLs.CDN_PLAIN}/palworld/img/interactive-map/tiles/{z}/{x}/{y}.webp`}
        noWrap
        bounds={[
          [0, 0],
          [-256, 256],
        ]}
      />
      {Boolean(toggles.pal) && <PalHeatmapMarkers palId={toggles.pal} />}
      {(!toggles.alphaPals || toggles.pal) && (
        <AlphaPals highlightPal={toggles.pal} />
      )}
      {Boolean(toggles.respawn) && <RespawnMarkers />}
      {Boolean(toggles.towers) && <TowerMarkers />}
      {Boolean(toggles.fastTravel) && <FastTravelMarkers />}
      {Boolean(toggles.dungeons) && <DungeonMarkers />}
      {Boolean(toggles.lifmunk) && <LifmunkMarkers />}
      {Boolean(toggles.eggs) && <EggMarkers />}
      {Boolean(toggles.chests) && <ChestMarkers />}
      {Boolean(toggles.merchants) && <MerchantMarkers />}
      {Boolean(toggles.palMerchants) && <PalMerchantMarkers />}
      {Boolean(toggles.enemyCamps) && <EnemyCampMarkers />}
      {Boolean(toggles.skillFruits) && <SkillFruitMarkers />}
      {Boolean(toggles.npcs) && <NpcMarkers />}
      {Boolean(toggles.notes) && <NoteMarkers />}
    </MapContainer>
  );
}
