import React, { useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Circle, useMap, useMapEvent } from "react-leaflet";
import { css } from "goober";
import type { TFunction } from "i18next";
import L from "leaflet";

import { appURLs } from "@/app/app-urls.mjs";
import {
  SPAWN_COLORS,
  useSpawnData,
} from "@/game-palworld/components/PalInfo.jsx";
import type { Pal, Point } from "@/game-palworld/models/model-wiki.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";

const latitudeMax = 256;
const longitudeMax = 256;
const world_min = {
  X: -582888.0,
  Y: -301000.0,
  Z: 1.0,
};
const world_max = {
  X: 335112.0,
  Y: 617000.0,
  Z: 1.0,
};

function palCoordinateToLeaflet([x, y]: readonly [number, number, number?]): [
  number,
  number,
] {
  const x1 = (x - world_min.X) / (world_max.X - world_min.X);
  const y1 = (y - world_min.Y) / (world_max.Y - world_min.Y);

  const lng = longitudeMax * y1;
  const lat = latitudeMax * x1 - latitudeMax;

  return [lat, lng];
}

const createCustomMarkerIcon = (
  component: React.ReactElement,
  size = 36,
  className?: string,
) => {
  const html = renderToStaticMarkup(component);
  return L.divIcon({
    html,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 2],
    className: `map-icon-wrapper ${className}`,
  });
};

const AlphaPalMarkerIcon = ({
  imgSrc,
  level,
}: {
  imgSrc: string;
  level: number;
}) => (
  <div className="alpha-icon flex column align-center">
    <img src={imgSrc} width={36} height={36} />
    <p className="type-caption">{level}</p>
  </div>
);
function AlphaPalMarker({
  t,
  pal,
  bossLvl,
  point,
}: {
  t: TFunction;
  pal: Pal;
  bossLvl: number;
  point: Point;
}) {
  const map = useMap();

  useEffect(() => {
    const icon = createCustomMarkerIcon(
      <AlphaPalMarkerIcon
        imgSrc={`${appURLs.CDN_PLAIN}/${pal.imageUri}`}
        level={bossLvl}
      />,
    );

    const latLang = palCoordinateToLeaflet(point);
    const marker = L.marker(latLang, { icon: icon, riseOnHover: true }).addTo(
      map,
    );

    const tooltip = t(...pal.label);
    marker.bindTooltip(tooltip, {
      offset: new L.Point(20, 0),
      direction: "right",
      sticky: false,
      permanent: false,
      className: "type-caption icon-tooltip",
    });

    return () => {
      marker.remove();
    };
  }, [bossLvl, map, pal.imageUri, pal.label, point, t]);

  return null;
}

const enemyCampIcon = (size: number) => css`
  width: ${size}px;
  height: ${size}px;
  background-color: var(--red);
  border-radius: 50%;
`;

const CustomMarkerIcon = ({
  iconSrc,
  size = 36,
}: {
  iconSrc: string;
  size?: number;
}) => {
  if (iconSrc === "enemy-camp") {
    return <div className={["custom-icon", enemyCampIcon(size)].join(" ")} />;
  }
  return (
    <div className="custom-icon">
      <img width={size} height={size} src={iconSrc} />
    </div>
  );
};

type CustomLocMarker = {
  name?: string;
  point?: [number, number];
  size?: number;
  iconSrc: string;
};
function CustomLocationMarker(
  p: Omit<CustomLocMarker, "point"> & {
    point?: readonly [number, number];
  },
);
function CustomLocationMarker({
  name,
  point,
  size = 36,
  iconSrc,
}: CustomLocMarker) {
  const map = useMap();

  useEffect(() => {
    const icon = createCustomMarkerIcon(
      <CustomMarkerIcon iconSrc={iconSrc} size={size} />,
    );

    const marker = L.marker(point, { icon, riseOnHover: true }).addTo(map);
    if (name) {
      const tooltip = name;
      marker.bindTooltip(tooltip, {
        offset: new L.Point(20, 0),
        direction: "right",
        sticky: false,
        permanent: false,
        className: "type-caption icon-tooltip",
      });
    }

    return () => {
      marker.remove();
    };
  }, [map, name, iconSrc, size, point]);

  return null;
}

export function AlphaPals({ highlightPal }: { highlightPal?: string }) {
  const { t } = useTranslation();
  const { pals, mapData } = useWikiData();

  const allAlphas: [Pal, number, Point][] = useMemo(
    () =>
      pals &&
      mapData &&
      mapData.alphaPals.map(([alphaId, lvl, point]) => [
        Object.values(pals).find(({ internalId }) => internalId === alphaId),
        lvl,
        point,
      ]),
    [mapData, pals],
  );

  const alphas = useMemo(
    () =>
      highlightPal
        ? allAlphas.filter(([pal]) => pal.id === highlightPal)
        : allAlphas,
    [allAlphas, highlightPal],
  );

  if (!alphas) return null;

  return alphas.map(([pal, lvl, point], i) => (
    <AlphaPalMarker key={i} t={t} pal={pal} bossLvl={lvl} point={point} />
  ));
}

const respawnIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/respawn_point.webp`;
export function RespawnMarkers() {
  const { t } = useTranslation();
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.respawns.map(([label, point], i) => (
    <CustomLocationMarker
      key={i}
      iconSrc={respawnIcon}
      name={t(...label)}
      point={point}
    />
  ));
}

const towerIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/tower.webp`;
export function TowerMarkers() {
  const { t } = useTranslation();
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.towers.map(([label, point], i) => (
    <CustomLocationMarker
      key={i}
      iconSrc={towerIcon}
      name={t(...label)}
      point={point}
    />
  ));
}

const fastTravelIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/fast_travel.webp`;
export function FastTravelMarkers() {
  const { t } = useTranslation();
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.fastTravels.map(([label, point], i) => (
    <CustomLocationMarker
      key={i}
      iconSrc={fastTravelIcon}
      name={t(...label)}
      point={point}
    />
  ));
}

const dungeonIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/dungeon.webp`;
export function DungeonMarkers() {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.dungeons.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={dungeonIcon} point={point} />
  ));
}

const lifmunkIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/lifmunk_effigy.webp`;
export function LifmunkMarkers() {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.lifmunks.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={lifmunkIcon} point={point} />
  ));
}

const DEFAULT_RADIUS = 4;
export const PalHeatmapMarkers = ({ palId }: { palId: string }) => {
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS / 1.5);
  const { pals, spawnLocations: spawnMap } = useWikiData();

  const map = useMapEvent("zoomend", () => {
    const zoomLevel = map.getZoom();

    setRadius(DEFAULT_RADIUS / (zoomLevel * 1.5));
  });

  const spawnLocations = useSpawnData(pals?.[palId].internalId, spawnMap);
  return spawnLocations.map(([x, y, time], i) => (
    <Circle
      key={i}
      center={palCoordinateToLeaflet([x, y])}
      radius={radius}
      fillColor={SPAWN_COLORS[time]}
      fill={true}
      stroke={false}
      fillOpacity={0.6}
    />
  ));
};

const eggIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/egg.webp`;
export const EggMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.eggs.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={eggIcon} point={point} />
  ));
};

const chestIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/chest.webp`;
export const ChestMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.chests.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={chestIcon} point={point} />
  ));
};

const merchantIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/merchant.webp`;
export const MerchantMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.merchants.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={merchantIcon} point={point} />
  ));
};

const palMerchantIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/pal_merchant.webp`;
export const PalMerchantMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.palMerchants.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={palMerchantIcon} point={point} />
  ));
};

export const EnemyCampMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.enemyCamps.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc="enemy-camp" point={point} />
  ));
};

const skillFruitIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/skill_fruit.webp`;
export const SkillFruitMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.skillFruits.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={skillFruitIcon} point={point} />
  ));
};

const npcIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/npc.webp`;
export const NpcMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.npcs.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={npcIcon} point={point} />
  ));
};

const noteIcon = `${appURLs.CDN_PLAIN}/palworld/img/interactive-map/icons/note.webp`;
export const NoteMarkers = () => {
  const { mapData } = useWikiData();
  if (!mapData) return null;

  return mapData.notes.map((point, i) => (
    <CustomLocationMarker key={i} iconSrc={noteIcon} point={point} />
  ));
};
